<template>
    <v-card>
        <v-card-title>
            Visit Tutti Task UI:
        </v-card-title>
        <v-card-text>
            <div v-if="projectName">
                <v-row align="center">
                    <v-col cols="2">
                        <b>Production URL</b>:
                    </v-col>
                    <v-col cols="10">
                        <div v-if="projectHasDiff">
                            <span class="warning--text font-weight-medium">Production URL is not available since "{{ projectName }}" seems to be changed since the last build.</span>
                            <v-btn outlined small class="ml-2" color="warning" :loading="rebuildingProject" @click="rebuildProject">Rebuild now</v-btn>
                        </div>
                        <div v-else>
                            <div v-if="alert.show">
                                <v-icon left color="error">mdi-alert</v-icon>
                                <span class="error--text font-weight-medium">{{ alert.message }}</span>
                            </div>
                            <div v-else class="d-flex align-center">
                                <div style="width:500px">
                                    <v-text-field
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                        :value="url"
                                        style="font-size:12px"
                                        :width="400"
                                    >
                                        <template v-slot:append>
                                            <copy-to-clipboard-btn x-small class="ml-1" :text="url"></copy-to-clipboard-btn>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div>
                                    <v-btn dark class="mx-2" color="indigo darken-2" @click="openUrlInNewTab(devUrl)">
                                        Start task
                                        <v-icon right>mdi-open-in-new</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col cols="2">
                        <b>Development URL</b>:
                    </v-col>
                    <v-col cols="10">
                        <div class="d-flex align-center">
                            <div style="width:500px">
                                <v-text-field outlined dense hide-details readonly :value="devUrl" style="font-size:12px" :width="400">
                                    <template v-slot:append>
                                        <copy-to-clipboard-btn x-small class="ml-1" :text="devUrl"></copy-to-clipboard-btn>
                                    </template>
                                </v-text-field>
                            </div>
                            <div>
                                <v-btn dark class="mx-2" color="grey darken-2" @click="openUrlInNewTab(devUrl)">
                                    Start task
                                    <v-icon right>mdi-open-in-new</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                Select project first.
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { getUrl } from "@/lib/tutti-env.js"
import CopyToClipboardBtn from "@/components/ui/CopyToClipboardBtn"
import { openUrlInNewTab } from '@/lib/utils'

export default {
    components: {
        CopyToClipboardBtn,
    },
    data: () => ({
        projectHasDiff: false,
        rebuildingProject: false,
        alert: {
            show: false,
            message: ''
        }
    }),
    props: [ 'projectName', 'client' ],
    computed: {
        url() { return getUrl(this.projectName); },
        devUrl() { return getUrl(this.projectName, true); },
    },
    methods: {
        openUrlInNewTab,
        async checkProjectDiff(){
            if(this.projectName) {
                try {
                    this.projectHasDiff = await this.client.resource.checkProjectDiff({ project_name: this.projectName });
                } catch (e) {
                    if(e.code===this.client.ERROR.SYSTEM_BUILD.CONNECTION_FAILURE){
                        this.alert.show = true;
                        this.alert.message = 'Connection to system rebuild server failed. This may result in not properly reflecting changes in projects when deployed in markets.';
                    }
                }
            }
        },
        async rebuildProject(){
            this.rebuildingProject = true;
            try {
                await this.client.resource.rebuildProject({ project_name: this.projectName });
                this.$root.$emit('tutti-snackbar', 'success', 'Project rebuild finished');
                this.projectHasDiff = false;
            } catch (e) {
                this.$root.$emit('tutti-snackbar', 'error', 'Project rebuild failed');
            } finally {
                this.rebuildingProject = false;
            }
        }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.checkProjectDiff();
        });
    },
    watch: {
        projectName() {
            this.checkProjectDiff();
        }
    }
}
</script>
