<template>
    <v-main class="mt-10 grey lighten-4">
        <v-row justify="center">
            <v-col cols="9">
                <task-ui-urls-card :projectName="projectName" :client="client" />
            </v-col>
            <v-col cols="9">
                <project-list-card :client="client" />
            </v-col>
        </v-row>
    </v-main>
</template>
<script>
import ProjectListCard from './ProjectListCard'
import TaskUIURLsCard from './TaskUIURLsCard'

export default {
    components: {
        ProjectListCard,
        'task-ui-urls-card': TaskUIURLsCard
    },
    props: [ 'projectName', 'client' ],
}
</script>
