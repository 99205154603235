<template>
    <v-card>
        <v-card-title>
            Projects
        </v-card-title>
        <v-data-table
            dense
            sort-desc
            :headers="headers"
            :items="projectItems"
            sort-by="last_modified"
        >
            <template v-slot:item.numTemplates="{ item }">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.numTemplates }}</span>
                    </template>
                    <span v-html="item.templates.join('<br>')" />
                </v-tooltip>
            </template>
            <template v-slot:item.last_modified="{ item }">
                {{ standardDateTimeFormat(item.last_modified) }}
            </template>
        </v-data-table>
    </v-card>
</template>
<script>
import { standardDateTimeFormat } from '@/lib/utils'

export default {
    data: () => ({
        headers: [
            { value: 'name', text: 'Name' },
            { value: 'numTemplates', text: '# Templates' },
            { value: 'last_modified', text: 'Last Modified' },
        ],
        projectItems: [],
    }),
    props: ['client'],
    methods: {
        standardDateTimeFormat
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            let projects = await this.client.resource.listProjects();
            for(const i in projects) {
                let project = projects[i];
                project.templates = await this.client.resource.listTemplates({ project_name: project.name });
                project.numTemplates = project.templates.length;
            }
            this.projectItems = projects;
        });
    }
}
</script>
